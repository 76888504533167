<script setup>
import { computed } from 'vue';
import { Link, usePage } from '@inertiajs/vue3';
import JetApplicationMark from '@/Components/ApplicationMark.vue';

const page = usePage();
const navigationItems = computed(() => page.props.navigations.footer);
const logos = computed(() => page.props.images.footerLogos);
const homeUrl = computed(() => page.props.homeUrl);

defineProps({
    extraItems: {
        type: Array,
        default: () => [],
    },
});
</script>

<template>
    <footer>
        <div class="bg-deep-teal-700 purple-theme:bg-purple-700 text-white font-semibold">
            <div class="container px-4 py-14 mx-auto">
                <div class="flex flex-col items-center font-semibold text-sm text-center gap-6">
                    <div class="sm:flex sm:space-x-9 items-end">
                        <Link
                            class="inline-flex text-white focus:accent-sunset-orange-500 focus:outline-none mb-4 sm:mb-0"
                            :href="homeUrl"
                            aria-label="Home"
                        >
                            <JetApplicationMark class="block h-auto w-16 pb-[0.35rem]" />
                        </Link>

                        <div class="font-semibold text-xs mb-1.5">
                            {{ $t('components.footer.cooperation') }}
                        </div>

                        <ul class="flex flex-col sm:flex-row sm:space-x-9">
                            <template
                                v-for="(logo, index) in logos"
                                :key="index"
                            >
                                <li
                                    v-if="logo.image"
                                    class="sm:inline-flex"
                                >
                                    <a
                                        v-if="logo.link"
                                        :href="logo.link.url"
                                        :title="logo.title"
                                        class="inline-flex"
                                        target="_blank"
                                        rel="noreferrer nofollow"
                                    >
                                        <img
                                            class="h-10"
                                            :src="logo.image.permalink"
                                            :alt="logo.image.alt"
                                            loading="lazy"
                                        >
                                    </a>

                                    <img
                                        v-else
                                        class="h-10"
                                        :src="logo.image.permalink"
                                        :alt="logo.image.alt"
                                        :title="logo.title"
                                    >
                                </li>
                            </template>
                        </ul>
                    </div>

                    <div class="grid grid-cols-1 sm:flex sm:items-center gap-y-3 gap-x-6">
                        <button
                            v-for="(item, index) in extraItems"
                            :key="index"
                            type="button"
                            :onclick="item?.onclick"
                            class="hover:text-sunset-orange-500 focus:text-sunset-orange-500 focus:outline-none transition-colors"
                            :class="item?.class"
                        >
                            {{ item.text }}
                        </button>

                        <Link
                            v-for="(item, index) in navigationItems"
                            :key="index"
                            :href="item.permalink"
                            class="hover:text-sunset-orange-500 focus:text-sunset-orange-500 focus:outline-none transition-colors"
                        >
                            {{ item.title }}
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>
