<script setup>
import { computed, ref } from 'vue';
import { usePage } from '@inertiajs/vue3';
import AutocompleteSearch from '@/Components/Search/AutocompleteSearch.vue';

const page = usePage();

const environment = computed(() => page.props.environment);
const locale = computed(() => page.props.locale);
const siteName = computed(() => page.props.siteName);

const searchComponent = ref(null);
</script>

<template>
    <AutocompleteSearch
        ref="searchComponent"
        :environment
        :locale
        :site-name
    />
</template>
