<template>
    <component :is="isCustom ? svg : component" />
</template>

<script setup>
import { computed, defineComponent, defineAsyncComponent, h } from 'vue';

const props = defineProps({
    icon: {
        type: [String, Object],
        required: true,
    },
});

const isCustom = computed(() => typeof props.icon === 'object');

const svg = computed(() => defineComponent({
    render: () => h('span', {
        innerHTML: props.icon.svg,
    }),
}));

const component = computed(() => {
    const icon = props.icon;

    return defineAsyncComponent({
        loader: () => import(`../../icons/ee-hub/${icon}.svg`),
        loadingComponent: 'span',
        delay: 0,
    });
});
</script>
