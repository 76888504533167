<script setup>
import JetResponsiveNavLink from '@/Components/ResponsiveNavLink.vue';
import Icon from '@/Components/Icon.vue';
import { useNavigationStore } from '@/stores/navigation.js';

const store = useNavigationStore();

defineProps({
    items: {
        type: Array,
        required: true,
    },
    hideIcons: {
        type: Boolean,
        default: false,
    },
    level: {
        type: Number,
        default: 1,
    },
});

const paddingMap = {
    1: 'pl-4',
    2: 'pl-8',
    3: 'pl-12',
    4: 'pl-16',
};
</script>

<template>
    <!-- TODO: add active state when route exists -->
    <JetResponsiveNavLink
        v-for="(subitem, sindex) in items"
        :key="sindex"
        font-size="text-base"
        :class="[paddingMap[level]]"
        :href="subitem.permalink || ''"
        :active="subitem.is_current"
        :as="[subitem.is_external ? 'a' : 'Link']"
        :with-icon="!!subitem.icon"
        @click="store.setShowingNavigationDropdown(! store.showingNavigationDropdown)"
    >
        <Icon
            v-if="!hideIcons && subitem.icon"
            class="shrink-0 h-6 w-6 mr-2 opacity-75"
            aria-hidden="true"
            :icon="{ svg: subitem.icon }"
        />

        {{ subitem.title }}
    </JetResponsiveNavLink>
</template>
