import { defineStore } from 'pinia';
import { ref } from 'vue';
import { useThrottleFn } from '@vueuse/core';

export const useNavigationStore = defineStore('navigation', () => {
    const showingNavigationDropdown = ref(false);

    const setShowingNavigationDropdown = useThrottleFn(value => {
        showingNavigationDropdown.value = value;
    }, 1, true, false);

    return { showingNavigationDropdown, setShowingNavigationDropdown };
});
