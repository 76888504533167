<script setup>
import { Link } from '@inertiajs/vue3';

const props = defineProps({
    href: String,
    as: String,
    active: Boolean,
    withIcon: Boolean,
});

const classes = [
    props.active
        ? 'text-sunset-orange-500'
        : 'text-gray-800',
    props.withIcon
        ? 'flex items-center'
        : 'block',
];
</script>

<template>
    <div>
        <button
            v-if="as == 'button'"
            type="submit"
            class="w-full px-3 py-2 text-sm text-left hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition-colors"
            :class="classes"
        >
            <slot />
        </button>

        <a
            v-else-if="as =='a'"
            :href
            class="px-3 py-2 text-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition-colors"
            :class="classes"
        >
            <slot />
        </a>

        <Link
            v-else
            :href
            class="block px-3 py-2 text-sm hover:bg-gray-100 focus:outline-none focus:bg-gray-100 transition-colors"
            :class="classes"
        >
            <slot />
        </Link>
    </div>
</template>
