<script setup>
import { Link } from '@inertiajs/vue3';
import { Dialog, DialogPanel, TransitionChild, TransitionRoot } from '@headlessui/vue';
import { XMarkIcon } from '@heroicons/vue/24/outline/index.js';
import { useNavigationStore } from '@/stores/navigation.js';
import MobileMenuContent from '@/Layouts/Partials/MobileMenuContent.vue';

const store = useNavigationStore();
</script>

<template>
    <TransitionRoot
        as="template"
        :show="store.showingNavigationDropdown"
    >
        <Dialog
            as="div"
            class="relative z-10"
            @close="store.setShowingNavigationDropdown(false)"
        >
            <TransitionChild
                as="template"
                enter="ease-in-out duration-500"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="ease-in-out duration-500"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 backdrop-blur-sm bg-black/60 transition-opacity" />
            </TransitionChild>

            <div class="fixed inset-y-0 right-0 overflow-hidden">
                <TransitionChild
                    as="template"
                    enter="transform transition ease-in-out duration-500 sm:duration-700"
                    enter-from="translate-x-full"
                    enter-to="translate-x-0"
                    leave="transform transition ease-in-out duration-500 sm:duration-700"
                    leave-from="translate-x-0"
                    leave-to="translate-x-full"
                >
                    <DialogPanel class="w-80 h-full bg-white overflow-y-scroll shadow-xl pointer-events-auto">
                        <div class="bg-deep-teal-700 flex justify-end py-4 px-4">
                            <button
                                type="button"
                                class="text-white p-1 rounded-md focus:outline-none focus:ring-1 focus:ring-sunset-orange-500"
                                @click="store.setShowingNavigationDropdown(false)"
                            >
                                <XMarkIcon class="h-6 w-6" />
                            </button>
                        </div>

                        <div class="h-[calc(100%_-_4rem)] relative flex flex-col justify-between">
                            <MobileMenuContent />

                            <div class="sticky inset-x-0 bottom-0 px-4 pt-2 pb-6">
                                <Link
                                    v-if="!$page.props.auth.user"
                                    :href="route('login')"
                                    class="w-full flex justify-center font-bold text-base text-deep-teal-700 bg-white border-2 border-deep-teal-800 rounded-3xl hover:text-deep-teal-900 hover:border-deep-teal-900 hover:outline-deep-teal-900 focus:text-deep-teal-900 focus-visible:text-deep-teal-900 focus:border-deep-teal-900 focus-visible:border-deep-teal-900 focus:outline-deep-teal-900 focus-visible:outline-none disabled:text-deep-teal-300 disabled:outline-deep-teal-300 px-5 py-1.5 transition-colors"
                                    @click="store.setShowingNavigationDropdown(false)"
                                >
                                    {{ $t('auth.login.form.submit') }}
                                </Link>

                                <Link
                                    v-else
                                    :href="route('logout')"
                                    method="post"
                                    class="w-full flex justify-center font-bold text-base text-deep-teal-700 bg-white border-2 border-deep-teal-800 rounded-3xl hover:text-deep-teal-900 hover:border-deep-teal-900 hover:outline-deep-teal-900 focus:text-deep-teal-900 focus-visible:text-deep-teal-900 focus:border-deep-teal-900 focus-visible:border-deep-teal-900 focus:outline-deep-teal-900 focus-visible:outline-none disabled:text-deep-teal-300 disabled:outline-deep-teal-300 px-5 py-1.5 transition-colors"
                                    @click="store.setShowingNavigationDropdown(false)"
                                >
                                    {{ $t('components.navigation.profile.logout') }}
                                </Link>
                            </div>
                        </div>
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>
