<script setup>
import { MagnifyingGlassIcon } from '@heroicons/vue/24/outline';
import { useSearchStore } from '@/stores/search.js';

const searchStore = useSearchStore();

const openSearch = () => {
    searchStore.open();
};
</script>

<template>
    <button
        class="text-white hover:text-sunset-orange-500 focus:text-sunset-orange-500 focus:outline-none transition-colors"
        aria-label="Open search"
        @click="openSearch"
    >
        <MagnifyingGlassIcon
            class="h-6 w-6"
            aria-hidden="true"
        />

        <span class="sr-only">
            Search
        </span>
    </button>
</template>
