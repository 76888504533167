import { createApp, h } from 'vue';
import { createPinia } from 'pinia';
import { createInertiaApp } from '@inertiajs/vue3';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import { createI18n } from 'vue-i18n';
import * as Sentry from '@sentry/vue';
import cssHasPseudo from 'css-has-pseudo/browser';
import { MotionPlugin } from '@vueuse/motion';
import FloatingVue from 'floating-vue';
import 'floating-vue/dist/style.css';
import '../css/app.css';
import { ZiggyVue } from '../../vendor/tightenco/ziggy/dist/index.js';
import './bootstrap.js';
import AppLayout from './Layouts/AppLayout.vue';

createInertiaApp({
    progress: {
        color: '#024047',
    },
    async resolve(name) {
        const { default: component } = await resolvePageComponent(`./Pages/${name}.vue`, import.meta.glob('./Pages/**/*.vue'));
        component.layout ??= AppLayout;
        return component;
    },
    setup({ el, App, props, plugin }) {
        const pinia = createPinia();

        const i18n = createI18n({
            legacy: false,
            globalInjection: true,
            locale: props.initialPage.props.locale,
            fallbackLocale: 'de',
            messages: props.initialPage.props.translations,
        });

        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(pinia)
            .use(i18n)
            .use(MotionPlugin)
            .use(ZiggyVue)
            .use(FloatingVue);

        Sentry.init({
            app,
            dsn: import.meta.env.VITE_SENTRY_DSN,
            tracesSampleRate: 0.1,
            replaysSessionSampleRate: 0.1,
            ReplaysOnErrorSampleRate: 1,
            environment: import.meta.env.VITE_SENTRY_ENV,

            integrations: [
                new Sentry.BrowserTracing(),
                Sentry.replayIntegration({
                    maskAllText: true,
                    blockAllMedia: true,
                }),
            ],
        });

        return app.mount(el);
    },
});

/**
 * Remove this along with the css-has-pseudo dependency
 * when support for :has() lands in stable Firefox
 *
 * https://caniuse.com/css-has
 * https://bugzilla.mozilla.org/show_bug.cgi?id=418039
 */
cssHasPseudo(document, {
    hover: true,
});

// Init datalayer for gtm
window.dataLayer ||= [];
